<template>
	<div>
		<CertificadoConclusaoPosDigital />
	</div>
</template>

<script>
import CertificadoConclusaoPosDigital from '@components/certificados/CertificadoConclusaoPosDigital';
export default {
	name: 'CertificadoPosDigital',
	components: {
		CertificadoConclusaoPosDigital
	}
}
</script>

<style>

</style>
