<template>
	<div class="card">
		<b-loading
			v-model="isLoading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<header class="card-header">
			<p class="is-size-5 card-header-title is-justify-content-center has-text-centered">
				Acompanhe o status do seu certificado de conclusão do curso {{ getNomeCurso | formatText }}
			</p>
		</header>
		<div class="card-content">
			<div :class="[{'is-flex-direction-column': isMobile, 'my-6': !isMobile }, 'is-flex is-justify-content-space-around mx-6']">
				<div :class="[{'my-5': isMobile}, 'is-flex is-flex-direction-column is-align-items-center']">
					<Icon
						:file="'certificado_confeccao'"
						:size="isMobile ? 60: 80"
						:class="[status === 1 ? hasActiveEffect : 'isGreyIcon' , 'app-card-icon']"
					/>
					<p :class="['is-size-5', 'has-text-centered', status != 1 ? 'isGreyIcon': '']">
						Em processo de confecção
					</p>
				</div>

				<div
					v-if="!isMobile"
					class="is-flex-direction-column is-flex is-justify-content-center mb-6"
				>
					<span class="animate__animated animate__fadeInLeft animate__fast animate__delay-0.5s gg-arrow-long-right" />
				</div>

				<div :class="[{'my-5': isMobile}, 'is-flex is-flex-direction-column is-align-items-center']">
					<Icon
						:file="'certificado_assinatura'"
						:size="isMobile ? 60: 80"
						:class="[status === 2 || status === 3 ? hasActiveEffect : 'isGreyIcon' , 'app-card-icon']"
					/>
					<p :class="['is-size-5', 'has-text-centered', status != 2 && status != 3 ? 'isGreyIcon': '']">
						Em processo de assinatura
					</p>
				</div>

				<div
					v-if="!isMobile"
					class="is-flex-direction-column is-flex is-justify-content-center mb-6"
				>
					<span class="animate__animated animate__fadeInLeft animate__fast animate__delay-0.5s gg-arrow-long-right" />
				</div>

				<div>
				<div :class="[{'my-5': isMobile}, {'is-clickable': status === 4 }, 'is-flex is-flex-direction-column is-align-items-center']" @click="status === 4 ? downloadCertificate() : ''">
						<Icon
							:file="'certificado_download'"
							:size="isMobile ? 60: 80"
							:class="[status === 4 ? hasActiveEffect : 'isGreyIcon' , 'app-card-icon']"
						/>
						<p :class="['is-size-5', 'has-text-centered', status != 4 ? 'isGreyIcon': '']">
							Disponível para download
						</p>
						<p
							v-show="status === 4"
							class="is-size-7 has-text-centered"
						>
							<strong>(Baixe seu certificado digital clicando no ícone)</strong>
						</p>
					</div>

					<p v-show="status === 4" class="is-size-7 has-text-centered is-flex-direction-column mt-4" @click="status === 4 ? downloadComprovanteAssinatura() : ''">
						Baixe seu documento comprobatório de assinatura <strong class="is-clickable">clicando aqui</strong>
					</p>
				</div>
			</div>
		</div>
		<footer class="card-footer">
			<p :class="[{ 'mx-6 px-6': !isMobile, 'px-2': isMobile }, 'has-text-centered py-4']">
				A primeira via de seu certificado de conclusão do curso de pós graduação estará disponível para emissão em até 60 dias após a solicitação,
				caso seu certificado ainda não esteja disponível no ícone <strong> Disponível para download</strong>.
			</p>
		</footer>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import { mapActions, mapGetters } from 'vuex';
import { capitalize } from 'lodash';

export default {
	name: "CertificadoConclusaoPosDigital",
	components: {
		Icon,
	},
	filters: {
		formatText(value) {
			if (!value) return;

			return value
				.split(' ')
				.map(text => {
					return text.length > 2 ? capitalize(text) : text.toLowerCase();
				})
				.join(' ');
		},
	},
	computed: {
		...mapGetters('aluno/certificados', [
			'getStatus',
			'isLoading'
		]),
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),
		hasActiveEffect() {
			return 'animate__animated animate__headShake animate__fast animate__delay-2s animate__repeat-3'
		},
		status() {
			return parseInt(this.getStatus) || 1;
		},
		...mapGetters('ui', [
			'isMobile'
		]),
		getNomeCurso() {
			return this.getVinculoSelecionado.curs_nome || ''
		}
	},
	methods: {
		...mapActions('aluno/certificados', [
			'downloadCertificate',
			'downloadComprovanteAssinatura'
		])
	},
}
</script>

<style lang="scss" scoped>
	.gg-arrow-long-right {
			box-sizing: border-box;
			position: relative;
			display: block;
			transform: scale(var(--ggs,1));
			border-top: 2px solid transparent;
			border-bottom: 2px solid transparent;
			box-shadow: inset 0 0 0 2px;
			width: 95px;
			height: 8px;
			color: darkgrey;
	}
	.gg-arrow-long-right::after {
			content: "";
			display: block;
			box-sizing: border-box;
			position: absolute;
			width: 14px;
			height: 14px;
			border-top: 4px solid;
			border-right: 4px solid;
			transform: rotate(45deg);
			right: 0;
			bottom: -5px;
			color: darkgrey;
	}

	.isGreyIcon {
		filter: grayscale(1) opacity(0.4);
		color: darkgray;
	}
</style>
